import { storeToRefs } from 'pinia'
import { useCompaniesStore } from '~/stores/companies'
import { until } from '@vueuse/core'

export default defineNuxtRouteMiddleware(async ({ params }) => {
  const { companies, loadingStatus } = storeToRefs(useCompaniesStore())

  // wait for fetching companies as we need to check whether
  // the desination company is accessible by current user
  await until(loadingStatus).toBe(false, { timeout: 10000 })

  if (companies.value.length === 1) {
    return navigateTo(`/division/${companies.value[0].id}`, {
      replace: true,
    })
  }
})
